import PageHolder from "../../lib/UIBookBrd/PageHolder";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import {Styled} from "./Contact.styles";
import {useTranslation} from "react-i18next";
import {Contact__Values} from "../../PageValues";



const ContactPage = () =>{

    const {t} = useTranslation()

    console.log({Contact__Values})

    return (
        <Styled.Contact__Holder>
       <Styled.Contact__Section__Title>
           {t('lbl_contact')}
       </Styled.Contact__Section__Title>
            <>
                <Styled.Contact__Phone__Number>
                    {t('lbl_phone_numbers')}
                    {': '}
                    <a
                   href={`tel:+48 509 363 473`}
                   style={{
                       fontWeight:'bold',
                       color:"inherit",
                       textDecoration:'none'
                   }}
                    >
                        +48 509 363 473
                    </a>
                 

                </Styled.Contact__Phone__Number>
                <Styled.Contact__Phone__Number>
                    {t('lbl_email')}
                    {': '}
                    <a
                        href = "mailto: studio@s115.pl"
                        style={{
                            fontWeight:'bold',
                            color:"inherit",
                            textDecoration:'none'
                        }}
                    >
                        studio@s115.pl
                    </a>

                </Styled.Contact__Phone__Number>
                <Styled.Contact__Phone__Number>
                  Instagram
                    {': '}
                    <span
                        style={{
                            fontWeight:"bold",
                            cursor:'pointer'
                        }}
                    onClick={()=>{     window.open('https://www.instagram.com/s115studio/?hl=pl', '_blank')}}
                    >
                      S115
                    </span>

                </Styled.Contact__Phone__Number>
            </>
            <Styled.Contact__Section__Title>
                {t('lbl_want_to_reserve')}
            </Styled.Contact__Section__Title>
            <Styled.Contact__Phone__Number>
                {t('lbl_want_to_reserve_desc')}
            </Styled.Contact__Phone__Number>
            <Styled.Contact__Section__Title>
                {t('lbl_not_know_what_want')}
            </Styled.Contact__Section__Title>
            <Styled.Contact__Phone__Number>
                {t('lbl_not_know_what_want_desc')}
            </Styled.Contact__Phone__Number>
        </Styled.Contact__Holder>
    )
}
export default ContactPage