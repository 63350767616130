import styled from "styled-components";
import Image from "../../lib/UIBookBrd/Image";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

const Page__Hodler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 73px;
  height: auto;
`
const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
  padding-bottom: 30px;
`
const Main__Values__Holder = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({theme}) => theme.backgroundColor};
  @media (max-width: 900px) {
    border-top: 1px solid ${({theme}) => theme.color};
    width: 100%;
  }
`
const Images__Holder = styled.div`
  display: flex;
  flex-direction: column;
`
const Main__Image = styled(Image)`
  background-color: ${({theme}) => theme.backgroundColor};
  height: 300px;
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  @media (max-width: 900px) {
    width: 100%;
    object-fit: initial;
  }
`
const Small__Images__Holder = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: initial;
  max-width: 100%;
  overflow: auto hidden;
  background-color: ${({theme}) => theme.backgroundColor};
`
const Product__Title = styled.h2`
  margin: 10px 10px 0 10px;
  font-size: 25px;
  font-weight: bold;
  color: ${({theme}) => theme.color};
`
const OverView__Description = styled.div`
  margin: 10px 10px 0 10px;
  font-size: 18px;
  font-weight: bold;
  color: ${({theme}) => theme.color};
`
const Reserve__Button = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: max-content;
  max-height: 33.5px;
  border: 1px solid ${({theme}) => theme.color};
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

`
const Reserve__Label = styled.div`
  margin: auto;
  font-size: 20px;
  padding: 5px 10px;
  font-weight: bold;
  color: ${({theme}) => theme.color};

`
const Small__Image = styled(Image)`
  background-color: ${({theme}) => theme.backgroundColor};
  height: 60px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.color};
  object-fit: contain;
  width: 120px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 120px;

  &:first-of-type {
    margin-left: 5px;
  }
`
const Price__Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  color: ${({theme}) => theme.color};
`
const Technical__Values = styled.div`
  padding: 0 5px;
  background-color: ${({theme}) => theme.backgroundColor};
  color: ${({theme}) => theme.color};
  display: flex;
  flex-direction: column;
`
const Product__Essential__Values = styled(FlexBox)`
  margin-top: 5px;
  border-top: 1px solid ${({theme}) => theme.color};
  padding: 15px 10px;
  gap: 8px 0;
`
const Section__Title = styled(Text)`
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
`
const Price__And__Count__Holder = styled(FlexBox)`
  flex-direction: column;
`


export const Styled = {
    Price__And__Count__Holder,
    Section__Title,
    Product__Essential__Values,
    Technical__Values,
    Price__Label,
    Small__Image,
    Reserve__Button,
    Reserve__Label,
    OverView__Description,
    Product__Title,
    Small__Images__Holder,
    Main__Image,
    Images__Holder,
    Header,
    Main__Values__Holder,
    Page__Hodler
}