import { Styled } from "./MenuDrawer.styles"
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";


type MenuSectionPartProps = {
    sectionTitle: string
    sectionOptions: any[]
}


const MenuSectionPart = (
    {
        sectionTitle,
        sectionOptions
    }:MenuSectionPartProps
) =>{

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const onSectionTitleClick= ()=>{
        setIsOpen(!isOpen)
    }

    return (
        <Styled.Menu__Drawer__Section__Part>
            <Styled.Section__Title__Holder
            isOpen={isOpen}
            onClick={onSectionTitleClick}
            >
                <Styled.Section__Title>
                {sectionTitle}
                </Styled.Section__Title>
                <Styled.Section__Status__Chevron
                icon={isOpen ? faChevronUp: faChevronDown}
                />
            </Styled.Section__Title__Holder>
            {isOpen && (
                <Styled.Section__Values__Holder>
                    {sectionOptions.map((option:any, index: number)=>(
                        <Styled.Section__Option
                            isLast={sectionOptions.length === index + 1}
                        onClick={option?.action}
                        >
                            {option?.label}
                        </Styled.Section__Option>
                    ))}
                </Styled.Section__Values__Holder>
            )}
        </Styled.Menu__Drawer__Section__Part>
    )
}
export default MenuSectionPart