import styled from "styled-components";
import FlexBox from "../../lib/UIBookBrd/FlexBox";
import Text from "../../lib/UIBookBrd/Text";

const Contact__Holder = styled(FlexBox)`
flex-direction: column;
  width: 100%;
  margin-top: 73px;
  background-color: ${({theme})=>theme.backgroundColor};
  padding-bottom: 10px;
`
const Contact__Section__Title = styled(Text)`
  padding: 10px 5px;
  font-size: 25px;
  font-weight: bold;
`
const Type__Title = styled(Text)`
  padding: 10px 5px;
  font-size: 18px;
  font-weight: bold;
`
const Contact__Phone__Number = styled(Text)`
  font-size: 16px;
  margin: 4px 0;
  margin-left: 15px;
  line-height: 19px;
`

export const Styled = {
    Contact__Phone__Number,
    Type__Title,
    Contact__Section__Title,
    Contact__Holder
}