import FlexBox from "../../../lib/UIBookBrd/FlexBox";
import styled from "styled-components";
import Text from "../../../lib/UIBookBrd/Text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Menu__Drawer__Container = styled(FlexBox)`
flex-direction: column;
  min-width: 400px;
  margin: 10px 0;
  gap: 5px;
  @media(max-width: 400px){
    width: 100%;
  }
`
const Menu__Drawer__Section__Part = styled(FlexBox)`
flex-direction: column;
  width: calc(100% - 10px);
  margin: 0 5px;
  border-radius: 5px;
  background-color: ${({theme})=>theme.OfferListBackgroundColor};
  
`
const Section__Title__Holder = styled(FlexBox)<{isOpen: boolean}>`
flex-wrap: nowrap;
  cursor: pointer;
padding: 10px;
  border-bottom: 1px solid ${({theme, isOpen})=> isOpen ? theme.color : 'transparent'};

`
const Section__Title = styled(Text)`
font-size: 20px;
  font-weight: bold;
`
const Section__Status__Chevron = styled(FontAwesomeIcon)`
  color: ${({theme})=>theme.color};
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
height: 20px;
  width: 20px;
`
const Section__Values__Holder =styled(FlexBox)`
flex-direction: column;
`
const Section__Option = styled(Text)<{isLast:boolean}>`
font-size: 20px;
  cursor: pointer;
  padding: 10px 10px ;
  padding-left: 20px;
  border-bottom: 1px solid ${({theme, isLast})=> isLast ? 'transparent' : theme.color};
`



export const Styled = {
    Section__Option,
    Section__Values__Holder,
    Section__Status__Chevron,
    Section__Title,
    Menu__Drawer__Container,
    Menu__Drawer__Section__Part,
    Section__Title__Holder
}