import Drawer from "../../../lib/UIBookBrd/Drawer";
import { Styled } from "./MenuDrawer.styles";
import {useContext} from "react";
import {Local_Data} from "../../../Context";
import {useTranslation} from "react-i18next";
import MenuSectionPart from "./MenuSectionPart";
import {useNavigate} from "react-router";
import {ABOUT_US_PAGE, COMPANIES_LONG_TERM_RENT, COMPANIES_SERVICES, CONTACT_PAGE, SOCIAL_MEDIA} from "../../../Routes";
import useS115 from "../../../lib/Hooks/useS115";

const MenuDrawer = () =>{

    const [localData, setLocalDate] = useContext<any>(Local_Data);

    const {t} = useTranslation()

    const {navigatePage}= useS115()

    const onClose = () =>{
        setLocalDate((state:any)=>({
            ...state,
            isMenuOpen: false
        }))
    }

    const aboutList = [
        {
            label: t('lbl_contact'),
            action: () =>{
                navigatePage(CONTACT_PAGE)
                onClose()
            }
        },
        {
            label: t('lbl_why_us'),
            action: () =>{
                navigatePage(ABOUT_US_PAGE)
                onClose()
            }
        },
    ]

    const settingsList = [
        {
            label: t('lbl_change_language'),
            action: () =>{
                setLocalDate((state:any)=>({
                    ...state,
                    isOpeLanguage: true,
                    isMenuOpen: false
                }))
            }
        }
    ]


    return(
        <Drawer
        isOpen={localData?.isMenuOpen ?? false}
        position={'left'}
        title={t('lbl_navigation_menu')}
        onClose={onClose}
        >
            <Styled.Menu__Drawer__Container>
                <MenuSectionPart
                    sectionOptions={aboutList}
                    sectionTitle={t('lbl_about_us')}
                />
                <MenuSectionPart
                    sectionOptions={settingsList}
                    sectionTitle={t('lbl_settings')}
                />
            </Styled.Menu__Drawer__Container>
        </Drawer>
    )

}
export default MenuDrawer